<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { valueGetters } from "~/components/fragment-sets/basic_component/util";
  import type { ManageAPI } from "~/manage-api/types";
  import NewsCard from './NewsCard.svelte';
  import StoreCardFrontPage from "./StoreCardFrontPage.svelte";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  let _class = "";
  export { _class as class };
  const stores_context = getStoresContext(request_store_key);
  const { events, reverse_router, T, stores, scdata, locale } = stores_context;
  const { getLink, getText } = valueGetters(stores_context);

  $: left_lift_title  = $locale && getText("left_lift_title", fragment);
  $: right_lift_title  = $locale && getText("right_lift_title", fragment);
  $: newStoresIDS = $locale && getText("new_stores_ids", fragment);
  $: readMoreButtonLeft = $locale && getLink("read_more_button_left", fragment);
  $: readMoreButtonRight = $locale && getLink("read_more_button_right", fragment);
  $: more_left_href = readMoreButtonLeft?.href? readMoreButtonLeft.href : $reverse_router.routePurpose('events collection');
  $: more_right_href = readMoreButtonRight?.href? readMoreButtonRight.href : $reverse_router.routePurpose('stores collection');

  $: t = $T;

  $: news_shown = $events.filter((event) => event.showOnFrontPage).slice(0, 3);

  $: idsArray = newStoresIDS?.trim()?.split(',')?.map(function (item) {
    return parseInt(item, 10);
  });

  $: chosenStores = idsArray.map((i) => $stores.find((j) => j.id === i));
  
    
</script>

<div class="news-and-events {_class}">
  <div class="container">
    <div class="row">
      <div class="col-lg-1 col-xl-2 col-12" />
      <div class="col-lg-5 col-xl-4 col-12 mb-5">
        <!-- Events List -->
          <div class="row">
            <div class="col-2" />
            <div class="col-lg-8 col-12 col-lg-12">
              <div class="pt-5 pb-4 news-wrapper">
                {#if left_lift_title}
                  <h2 class="font-reliable">{left_lift_title}</h2>
                {/if}
                {#if news_shown && news_shown.length}
                  {#each news_shown as event}
                    <div class='card-wrapper'>
                      <NewsCard {request_store_key} {event} isSearchResult={false} />
                    </div>
                  {/each}
                {/if}
                <a href={more_left_href} class="mx-auto mt-5 mb-4 px-4 py-2 purple-outline-button"
                  >{readMoreButtonLeft?.text? readMoreButtonLeft.text : t`more-current-topics`}
                </a>
              </div>
            </div>
            <div class="col-2" />
          </div>
        <!-- ----------- -->
      </div>
      <div class="col-lg-5 col-xl-4 col-12">
        <!-- Stores List -->
        <div class="pt-5 store-wrapper">
          {#if right_lift_title}
            <h2 class="font-reliable">{right_lift_title}</h2>
          {/if}
          {#if chosenStores && chosenStores.length}
            {#each chosenStores as store}
              <div class="card-wrapper front-page">
                <StoreCardFrontPage 
                  {store}
                  href={$reverse_router.routeStore(store, $scdata)}
                  />
              </div>
            {/each}
          {/if}
          <a href={more_right_href} class="mx-auto mt-5 mb-4 px-4 py-2 purple-outline-button"
            >{readMoreButtonRight?.text? readMoreButtonRight.text : t`all-shops-and-services`}
          </a>
        </div>
        <!-- ---- -->
      </div>
      <div class="col-lg-1 col-xl-2 col-12" />
    </div>
  </div>
</div>


<style lang="scss">
  .news-and-events {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media screen and (min-width: 992px) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }
  .news-wrapper, .store-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0;
    background-color: #fff;

    h2 {
      text-align: center;
      margin-bottom: 32px;
      font-size: 45px;
      text-transform: uppercase;
    }
  }

  .card-wrapper {
    width: 100%;
    flex: 0 0 100%;
    padding: 0.5rem 1.5rem;
    padding-right: 0;
  }
</style>
