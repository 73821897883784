<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import type { ManageAPI } from "~/manage-api/types";
  import { type DirectImage } from "~/components/fragment-sets/basic_component/util";

  export let request_store_key: StoresKey;
  const stores_context = getStoresContext(request_store_key);
  const { reverse_router } = stores_context;

  export let event: ManageAPI.Event;
  export let isSearchResult: boolean = false;
  
  let title: string;
  let additionalHeading: string;
  let href: string;
  let img: DirectImage;

  $: {
    if (event) {
      title = event.title;
      additionalHeading = event.additionalHeading;
      href = $reverse_router.routeEvent(event);
      let src = "";
      if (event.images && event.images.length > 1) src = event.images[1];
      else if (event.images && event.images.length == 1) src = event.images[0];
      img = {
        src,
        alt: event.title,
      };
    }
  } 
</script>

<a {href} class="news-card event">  
  <div class="news-card-image-wrapper col-5">
    {#if img && img.src}
      <img width="150" height="150" class="logo-container"  src={img.src} alt={img.alt} />
    {/if}
  </div>
  <div class={isSearchResult ? 'col-12' : 'col-7 px-4'}>
    {#if additionalHeading}
      <div class="additionalHeading">{ additionalHeading }</div>
    {/if}
    <p class="news-card-title news-card" {title}> { title } </p>
  </div>
</a>

<style lang="scss">
  .news-card-image-wrapper {
    padding: 0 18px;
  }

  .additionalHeading {
    background-color: #000;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    padding: 4px;
    font-size: 12px;
  }
  .logo-container {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .news-card {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .news-card-title {
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-accent-primary-text);
    font-size: 14px;
    @media screen and (min-width: 576px) {
      -webkit-line-clamp: 5;
      font-size: 18px;
    }
  }
  .news-card-title:hover {
    color: var(--color-black);
  }
</style>
