<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";
  export let store: ManageAPI.Store;
  export let href: string;
</script>

<a class="store-card" {href}>
  <div class="store-card-image-wrapper col-5">
    <div class="logo-container">
      {#if store.logoURL}
        <img width="90" alt={'Highlight store - ' + store.name} src={store.logoURL}/>
      {/if}
    </div>
  </div>
  <div class="col-7 px-4">
    <span class="store-card-title store-card fw-bold">{store.name}</span>
  </div>
</a>

<style lang="scss">
  .store-card-image-wrapper {
    padding: 0 18px;
  }
  .logo-container:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  .logo-container {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.4px solid var(--color-accent-secondary-dark);
    border-radius: 50%;
    img {
      position: absolute;
      padding: 1rem;
    }
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .store-card {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;

    @media screen and (min-width: 992px) {
      background-color: #fff;
    }
  }

  .store-card-title {
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-accent-primary-text);
    font-size: 18px;
  }
</style>
